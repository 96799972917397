@mixin smallDeviceSize {
	@media only screen and (max-width: 768px) {
		@content;
	}
}

@mixin button {
	width: 18rem;
    padding: 0.5rem;
    margin: 2rem 0;
    border: 0.1rem solid var(--highlight-2);
    border-radius: 16rem;
    background-color: var(--primary-2);
    font-size: 1.8rem;
    cursor: pointer;
	&:hover {
    	background: var(--secondary-1);
    	transition: 1.5s;
	}
	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
		&:hover {
    		background: var(--primary-2);
		}
	}
	a {
		text-decoration: none;
	}
	@content;
}

@mixin link {
	font-family: 'Karla', sans-serif;
	font-size: 1.5rem;
	cursor: pointer;
	color: var(--primary-2);
	&:hover {
		color: var(--secondary-1);
		transition: 1.5s;
	}
	@content;
}

@mixin normalText {
	font-family: 'Karla', sans-serif;
	font-size: 1.5rem;
    line-height: 1.5;
	@content;
}

@mixin subtitle {
	font-size: 1.8rem;
	font-family: 'Karla', sans-serif;
	@content;
}

@mixin title {
	font-size: 4rem;
	font-family: 'Markazi Text', serif;
	@content;
}