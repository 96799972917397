@import '../../_mixins.scss';

.navBar {
    font-family: 'Karla', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    gap: 2rem;
    padding: 0.5rem;
    background-color: var(--highlight-1);
    @include smallDeviceSize {
        justify-content: space-evenly;
    }
}

.navList {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.9rem;
    @include smallDeviceSize {
        position: absolute;
        flex-direction: column;
        top: 100%;
        right: 6%;
        border-top: .1rem solid rgba(0, 0, 0, .1);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition: 1.5s;
	}
}

.navList.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: 1.5s;
}

.navLink {
    padding: 0.9rem 3rem;
    font-size: 1.8rem;
    text-decoration: none;
    width: 200%;
    text-align: center;
    color: var(--primary-1);
    font-family: 'Karla', serif;
    @include smallDeviceSize {
        padding-bottom: 0.9rem;
        border-bottom: 0.2rem solid var(--primary-1);
        background: var(--highlight-1);
    }
}



.navLink:hover {
    color: var(--primary-2);
    transition: 0.5s;
}

.logo {
    margin: 0.8rem;
}

.icon {
    display: none;
    width: 3rem;
    height: 3rem;
    transition: 1.5s;
    @include smallDeviceSize {
        display: initial;
    }
}