@import "../../mixins";

form {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    @include smallDeviceSize {
        width: auto;
    }
}

h1{
    @include title;
}

.dataInput {
    display: flex;
    justify-content: space-between;
}

label, input, select {
    @include subtitle
}

input, select {
    padding: 0.2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--highlight-2);
}

.submitButton {
    @include button {
        width: auto;
    }
}