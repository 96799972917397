@import '../../_mixins.scss';

.cardContainer {
    width: 25.5rem;
    display: flex;
    flex-direction: column;
    background: var(--primary-1);
    border-radius: 2rem 2rem 0 0;
}

.cardDetails {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 0;
    gap: 1rem;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    font-family: 'Markazi Text', serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary-2);
}

.cardPrice {
    color: var(--secondary-1);
}

.cardDescription {
    @include normalText {
        color: var(--highlight-1);
    }
}

.cardButton {
    @include link;
    width: fit-content;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.cardImage {
    border-radius: 2rem 2rem 0 0;
}

/* Rating card*/

.ratingcard_container {
    display: flex;
    flex-direction: column;
    width: 20rem;
    padding: 1.5rem;
    background: var(--primary-2);
    border-radius: 1rem;
    gap: 1rem;
}

.ratings {

}

.customer_details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.customer_name {
    @include subtitle;
}

.customer_img {

}

.review {
    @include normalText;
}