@import '../../_mixins.scss';

.about_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    gap: 5rem;
}

.about_details {
    width: 40rem;
}

.about_details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.about_header {
    .about_title {
        @include title;
    }
    .about_location {
        @include subtitle;
    }
}

.about_description {
    @include normalText;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.about_photo_wrapper {
    width: 30rem;
    height: 40rem;
    position: relative;
}

.about_photo {
    width: 65%;
}

.about_photo_up {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
}

.about_photo_down {
    position: absolute;
    bottom: 0;
    left: 0;
}