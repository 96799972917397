@import '../../_mixins.scss';

.heroContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--primary-1);
    color: var(--highlight-1);
    padding: 4rem;
    @include smallDeviceSize {
        padding: 0;
    }
}

.leftBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 5rem;
}

.leftBox .title {
    @include title;
    color: var(--primary-2);
}

.leftBox .subtitle {
    @include subtitle
}

.leftBox .description {
    @include normalText
}

.rightBox {
    padding: 3rem;
}

.rightBox img {
    width: 20rem;
}

.button {
    @include button
}